import { PlusSmIcon, SearchIcon, XIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { DrawerTrigger } from "~/design-system/Drawer";
import { Inline } from "~/design-system/Inline";
import { TextFieldInput } from "~/design-system/TextField";
import { CreateProjectDrawer } from "~/screens/App/components/CreateProjectDrawer/CreateProjectDrawer";
import { createComponentHook } from "~/types";
import { DrawerMenuSectionHeader } from "../../DrawerMenuSectionHeader";

type ProjectListHeaderProps = {
  closeDrawer: () => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSearchMode: React.Dispatch<React.SetStateAction<boolean>>;
  searchQuery?: string;
};

const useProjectListHeader = createComponentHook(
  (props: ProjectListHeaderProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onCreateProjectSuccess = (projectId: string) => {
      props.closeDrawer();
      navigate(`/project/${projectId}`);
    };
    const [searchActive, setSearchActive] = React.useState(false);

    const toggleSearch = () => {
      if (searchActive) {
        setSearchActive(false);
        props.setSearchMode(false);
        props.setSearchQuery(undefined);
      } else {
        setSearchActive(true);
        props.setSearchMode(true);
      }
    };

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      props.setSearchQuery(e.target.value);
    };

    return {
      actions: {
        onCreateProjectSuccess,
        onSearchInputChange,
        toggleSearch,
      },
      state: {
        searchActive,
      },
      t,
    };
  }
);

const ProjectListHeader = (props: ProjectListHeaderProps) => {
  const { actions, state, t } = useProjectListHeader(props);
  return (
    <Box
      paddingX="small"
      paddingY="xxsmall"
      display="flex"
      justifyContent="spaceBetween"
      alignItems="center"
      height="touchable"
      gap="small"
    >
      {state.searchActive ? (
        <Box
          css={{
            "& input[type='text']": {
              background: "transparent",
              borderColor: "$neutral700",
              color: "$white",
            },
            flex: 1,
          }}
        >
          <TextFieldInput
            name="q"
            value={props.searchQuery}
            leadingIcon={<SearchIcon />}
            onChange={actions.onSearchInputChange}
            autoFocus
          />
        </Box>
      ) : (
        <DrawerMenuSectionHeader
          data-intercom-target="drawer-header-projects"
          title={t(
            "screens.NavigationDrawer.ProjectListMenu.myProjects",
            "My Projects"
          )}
        />
      )}
      <Inline space="xxsmall" width="auto">
        <Button
          variant="transparent"
          size="standard"
          circular={true}
          css={{
            "&:hover": {
              color: "$gray",
            },
            color: "white",
            height: "$buttonHeight",
            width: "$buttonHeight",
          }}
          onClick={actions.toggleSearch}
          dataIntercomTarget="navigation-drawer-project-list-toggle-search"
        >
          {state.searchActive ? <XIcon /> : <SearchIcon />}
        </Button>
        <CreateProjectDrawer onSuccess={actions.onCreateProjectSuccess}>
          <DrawerTrigger asChild>
            <Button
              circular
              variant="transparent"
              size="standard"
              css={{
                "&:hover": {
                  color: "$gray",
                },
                color: "$white",
                height: "$buttonHeight",
                width: "$buttonHeight",
              }}
              dataIntercomTarget="navigation-drawer-project-list-add"
            >
              <PlusSmIcon />
            </Button>
          </DrawerTrigger>
        </CreateProjectDrawer>
      </Inline>
    </Box>
  );
};

export { ProjectListHeader };

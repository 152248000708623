import type * as Types from '../../../../../../../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddUserApprovalToFileVersionMutationVariables = Types.Exact<{
  input: Types.AddUserApprovalToFileVersionInput;
}>;


export type AddUserApprovalToFileVersionMutation = { addUserApprovalToFileVersion?: { __typename: 'AddUserApprovalToFileVersionErrors' } | { __typename: 'AddUserApprovalToFileVersionSuccess' } | null | undefined };

export type AddUserMultiApprovalToFilesVersionMutationVariables = Types.Exact<{
  input: Types.AddUserMultiApprovalToFilesVersionInput;
}>;


export type AddUserMultiApprovalToFilesVersionMutation = { addUserMultiApprovalToFilesVersion?: { __typename: 'AddMultiUserApprovalToFileVersionSuccess', file_version_ids: Array<string> } | { __typename: 'AddUserMultiApprovalToFilesVersionErrors' } | null | undefined };


export const AddUserApprovalToFileVersionDocument = gql`
    mutation addUserApprovalToFileVersion($input: AddUserApprovalToFileVersionInput!) {
  addUserApprovalToFileVersion(input: $input) {
    __typename
  }
}
    `;

export function useAddUserApprovalToFileVersionMutation() {
  return Urql.useMutation<AddUserApprovalToFileVersionMutation, AddUserApprovalToFileVersionMutationVariables>(AddUserApprovalToFileVersionDocument);
};
export const AddUserMultiApprovalToFilesVersionDocument = gql`
    mutation addUserMultiApprovalToFilesVersion($input: AddUserMultiApprovalToFilesVersionInput!) {
  addUserMultiApprovalToFilesVersion(input: $input) {
    __typename
    ... on AddMultiUserApprovalToFileVersionSuccess {
      file_version_ids
    }
  }
}
    `;

export function useAddUserMultiApprovalToFilesVersionMutation() {
  return Urql.useMutation<AddUserMultiApprovalToFilesVersionMutation, AddUserMultiApprovalToFilesVersionMutationVariables>(AddUserMultiApprovalToFilesVersionDocument);
};
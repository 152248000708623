import type * as Types from '../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProjectsMultiSelect_ProjectFragment = { id: string, name: string, avatar?: string | null | undefined };

export type GetProjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProjectsQuery = { projects: Array<{ id: string, name: string, avatar?: string | null | undefined }> };

export const ProjectsMultiSelect_ProjectFragmentDoc = gql`
    fragment ProjectsMultiSelect_project on projects {
  id
  name
  avatar
}
    `;
export const GetProjectsDocument = gql`
    query getProjects {
  projects(
    where: {_not: {org: {id: {_is_null: false}}}, org_project_summary: {is_archived: {_eq: false}}}
  ) {
    ...ProjectsMultiSelect_project
  }
}
    ${ProjectsMultiSelect_ProjectFragmentDoc}`;

export function useGetProjectsQuery(options: Omit<Urql.UseQueryArgs<GetProjectsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetProjectsQuery>({ query: GetProjectsDocument, ...options });
};
import { Color } from "@clovis/server/src/app/config/colors/colors";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "~/config/user/UserProvider";
import type { BadgeProps } from "~/design-system/Badge";
import { BadgesCluster } from "~/design-system/BadgesCluster";
import { useBreakpoint } from "~/design-system/hooks";
import {
  OrgSummaryProjectCategoryBadgeButton,
  ProjectCategoryBadge,
} from "~/screens/App/components/Badges";
import { createComponentHook } from "~/types";
import { useProjectOrgData_ProjectCategoriesQuery } from "./ProjectOrgCategories.graphql";

type ProjectOrgCategoriesProps = {
  projectId: string;
  badgeSize?: BadgeProps["size"];
  onClick?: (e: React.MouseEvent) => void;
  disableOnClick?: boolean;
  maxNumberOfBadges?: number;
  isDemo?: boolean;
};

const MAX_BADGES_BY_VIEW: Record<"desktop" | "mobile" | "tablet", number> = {
  desktop: 2,
  mobile: 1,
  tablet: 1,
};

const useProjectOrgCategories = createComponentHook(
  (props: ProjectOrgCategoriesProps) => {
    const { t } = useTranslation();
    const { breakpoint } = useBreakpoint();
    const { user } = useUser();
    const currentUserOrgId = user.active_org?.org.id;
    const queryContext = React.useMemo(
      () => ({ additionalTypenames: ["orgs"] }),
      []
    );
    const [{ data }] = useProjectOrgData_ProjectCategoriesQuery({
      context: queryContext,
      pause: !currentUserOrgId || props.isDemo,
      variables: { orgId: currentUserOrgId!, projectId: props.projectId },
    });

    const categories = props.isDemo
      ? [
          {
            color: Color.Amber,
            name: t(
              "screens.ProjectsListScreen.ProjectOrgCategories.demoLabel",
              "Demo project"
            ),
          },
        ]
      : data?.projects_by_pk?.org_project_summary?.[0]?.project_categories?.map(
          (pc) => ({
            color: pc.project_category.color,
            name: pc.project_category.name,
          })
        ) ?? [];

    return {
      state: {
        breakpoint,
        categories,
        orgId: currentUserOrgId,
        orgProjectSummaryId: data?.projects_by_pk?.org_project_summary?.[0]?.id,
      },
    };
  }
);

function ProjectOrgCategories(props: ProjectOrgCategoriesProps) {
  const { state } = useProjectOrgCategories(props);

  if (state.categories.length > 0) {
    return (
      <BadgesCluster
        max={props.maxNumberOfBadges ?? MAX_BADGES_BY_VIEW[state.breakpoint]}
        size={props.badgeSize}
        customPlusBadge={
          state.orgId && !props.disableOnClick && state.orgProjectSummaryId ? (
            <OrgSummaryProjectCategoryBadgeButton
              size={props.badgeSize}
              orgId={state.orgId}
              onClick={props.onClick}
              color={Color.Gray}
              hideIcon={true}
              orgProjectSummaryId={state.orgProjectSummaryId}
            >
              {"anythig"}
            </OrgSummaryProjectCategoryBadgeButton>
          ) : (
            <ProjectCategoryBadge hideIcon={true} size={props.badgeSize}>
              {"anythig"}
            </ProjectCategoryBadge>
          )
        }
      >
        {state.categories.map((category, i) => {
          return state.orgId &&
            !props.disableOnClick &&
            state.orgProjectSummaryId ? (
            <OrgSummaryProjectCategoryBadgeButton
              size={props.badgeSize}
              color={category.color as Color}
              orgId={state.orgId}
              onClick={props.onClick}
              orgProjectSummaryId={state.orgProjectSummaryId}
              key={`${category.name}-${i}`}
            >
              {category.name}
            </OrgSummaryProjectCategoryBadgeButton>
          ) : (
            <ProjectCategoryBadge
              size={props.badgeSize}
              color={category.color as Color}
              key={`${category.name}-${i}`}
            >
              {category.name}
            </ProjectCategoryBadge>
          );
        })}
      </BadgesCluster>
    );
  }
  return null;
}
export { ProjectOrgCategories };

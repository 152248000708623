import type * as Types from '../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProjectOrgData_ProjectCategoriesQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  projectId: Types.Scalars['uuid'];
}>;


export type ProjectOrgData_ProjectCategoriesQuery = { projects_by_pk?: { org_project_summary?: Array<{ id: string, project_categories: Array<{ project_category: { color: string, name: string } }> }> | null | undefined } | null | undefined };


export const ProjectOrgData_ProjectCategoriesDocument = gql`
    query ProjectOrgData_ProjectCategories($orgId: uuid!, $projectId: uuid!) {
  projects_by_pk(id: $projectId) {
    org_project_summary(where: {org_id: {_eq: $orgId}}) {
      id
      project_categories(order_by: {order: asc_nulls_first}) {
        project_category {
          color
          name
        }
      }
    }
  }
}
    `;

export function useProjectOrgData_ProjectCategoriesQuery(options: Omit<Urql.UseQueryArgs<ProjectOrgData_ProjectCategoriesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProjectOrgData_ProjectCategoriesQuery>({ query: ProjectOrgData_ProjectCategoriesDocument, ...options });
};
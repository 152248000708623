import { ConversionProgressEnum } from "@clovis/server/src/app/config/autodesk-forge/utils";
import * as React from "react";
import { Trans } from "react-i18next";
import type { UserContextType } from "~/config/user/UserProvider";
import type { UserWorkerStatus } from "./utils/types";

type WorkerStatusMap<T extends UserWorkerStatus["type"]> = {
  [K in T]: (
    ctx: { user: UserContextType["user"] },
    workerStatus: Extract<UserWorkerStatus, { type: K }>
  ) => {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    percentDone: number;
  }; // Extract the union member that has { type: K }
};

function createWorkerStatusMap<T extends UserWorkerStatus["type"]>(
  map: WorkerStatusMap<T>
) {
  return map;
}

const SuccessSubtitle = (
  <Trans i18nKey="workerStatus.SUCCESS.subtitle" defaults="Success !" />
);
const ErrorSubtitle = (
  <Trans i18nKey="workerStatus.ERROR.subtitle" defaults="An error occured" />
);
const TimeoutSubtitle = (
  <Trans
    i18nKey="workerStatus.TIMEOUT.subtitle"
    defaults="The job failed to finish in reasonable time"
  />
);
const UnknownStatusTitle = (
  <Trans i18nKey="workerStatus.UNKOWN.title" defaults="Unknown" />
);

function handleFinishedStatus(
  workerStatus: UserWorkerStatus,
  title: React.ReactNode
) {
  if (workerStatus.status === "SUCCESS") {
    return {
      percentDone: 100,
      subtitle: SuccessSubtitle,
      title,
    };
  }
  if (workerStatus.status === "ERROR") {
    return {
      percentDone: 100,
      subtitle: ErrorSubtitle,
      title,
    };
  }
  if (workerStatus.status === "TIMEOUT") {
    return {
      percentDone: 100,
      subtitle: TimeoutSubtitle,
      title,
    };
  }
  // Default case, should never occurs except if there is a mismatch between our retrieved data and our types
  return { percentDone: 0, subtitle: null, title: UnknownStatusTitle };
}

// TODO: find a way to be exhaustive on the keys with typescript (if we add a type in UserWorkerStatus, workerStatusMap shouldn't type-check)
const workerStatusMap = createWorkerStatusMap({
  "PROJECT/DOCUMENT/FILE/CONVERT_3D_FILE": (_ctx, workerStatus) => {
    const title = (
      <Trans
        i18nKey="workerStatus.FILE/CONVERT_3D_FILE_FOR_FORGE_VIEWER.title"
        defaults="Preparing your 3D file to help loading it fast"
        values={workerStatus}
      />
    );
    if (workerStatus.status === "PENDING") {
      if (workerStatus.payload.stepMessage === "Convert3DFileStatus.UPLOAD") {
        return {
          percentDone: Number(ConversionProgressEnum.Start),
          subtitle: (
            <Trans
              i18nKey="workerStatus.FILE/CONVERT_3D_FILE_FOR_FORGE_VIEWER.REQUEST"
              defaults="Uploading original 3d file to Viewer Cloud"
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "Convert3DFileStatus.CONVERSIONSTART"
      ) {
        return {
          percentDone: Number(ConversionProgressEnum.Pending),
          subtitle: (
            <Trans
              i18nKey="workerStatus.FILE/CONVERT_3D_FILE_FOR_FORGE_VIEWER.CONVERSION"
              defaults="We are converting your 3d file for faster loading"
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "Convert3DFileStatus.CONVERSIONHALF"
      ) {
        return {
          percentDone: Number(ConversionProgressEnum.HalfConversion),
          subtitle: (
            <Trans
              i18nKey="workerStatus.FILE/CONVERT_3D_FILE_FOR_FORGE_VIEWER.CONVERSION"
              defaults="We are converting your 3d file for faster loading"
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage === "Convert3DFileStatus.CONVERSIONEND"
      ) {
        return {
          percentDone: Number(ConversionProgressEnum.Success),
          subtitle: (
            <Trans
              i18nKey="workerStatus.FILE/CONVERT_3D_FILE_FOR_FORGE_VIEWER.CONVERSION"
              defaults="We are converting your 3d file for faster loading"
            />
          ),
          title,
        };
      }
    }
    // Handled messages if worker status is finished or unknown
    return handleFinishedStatus(workerStatus, title);
  },
  "PROJECT/DOCUMENT/FOLDER/CREATE_FILE_STRUCTURE_STATUS": (
    _ctx,
    workerStatus
  ) => {
    const title = (
      <Trans
        i18nKey="workerStatus.PROJECT/DOCUMENT/FOLDER/CREATE_FILE_STRUCTURE_STATUS.title"
        defaults="Importing file structure from zip"
        values={workerStatus}
      />
    );
    if (workerStatus.status === "PENDING") {
      if (
        workerStatus.payload.stepMessage === "CreateFileStructureStatus.UPLOAD"
      ) {
        return {
          percentDone: 50,
          subtitle: (
            <Trans
              i18nKey="workerStatus.PROJECT/DOCUMENT/FOLDER/CREATE_FILE_STRUCTURE_STATUS.PENDING.CreateFileStructureStatus.UPLOAD"
              defaults="Uploading..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage === "CreateFileStructureStatus.EXTRACT"
      ) {
        return {
          percentDone: 75,
          subtitle: (
            <Trans
              i18nKey="workerStatus.PROJECT/DOCUMENT/FOLDER/CREATE_FILE_STRUCTURE_STATUS.PENDING.CreateFileStructureStatus.EXTRACT"
              defaults="Extracting..."
            />
          ),
          title,
        };
      }
    } else if (workerStatus.status === "ERROR") {
      if (
        workerStatus.payload.stepMessage ===
        "CreateFileStructureStatus.CORRUPTED"
      ) {
        return {
          percentDone: 100,
          subtitle: (
            <Trans
              i18nKey="workerStatus.PROJECT/DOCUMENT/FOLDER/CREATE_FILE_STRUCTURE_STATUS.PENDING.CreateFileStructureStatus.CORRUPTED"
              defaults="Corrupted zip file"
            />
          ),
          title,
        };
      }
    }
    // Handled messages if worker status is finished or unknown
    return handleFinishedStatus(workerStatus, title);
  },
  "PROJECT/DOCUMENT/FOLDER/DOWNLOAD_FOLDER_STATUS": (_ctx, workerStatus) => {
    const title = (
      <Trans
        i18nKey="workerStatus.PROJECT/DOCUMENT/FOLDER/DOWNLOAD_FOLDER_STATUS.title"
        defaults="Downloading folder"
        values={workerStatus}
      />
    );
    if (workerStatus.status === "PENDING") {
      if (workerStatus.payload.stepMessage === "DownloadFolderStatus.ZIP") {
        return {
          percentDone: 50,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.REQUEST"
              defaults="Zip..."
            />
          ),
          title,
        };
      }

      if (workerStatus.payload.stepMessage === "DownloadFolderStatus.SENDING") {
        return {
          percentDone: 90,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.BROADCAST"
              defaults="Sending..."
            />
          ),
          title,
        };
      }
    }
    return handleFinishedStatus(workerStatus, title);
  },
  "PROJECT/GENERATE_APPROVAL_EXPORT_STATUS": (_ctx, workerStatus) => {
    const title = (
      <Trans
        i18nKey="workerStatus.PROJECT/GENERATE_APPROVAL_EXPORT_STATUS.title"
        defaults="Generating approvals export"
        values={workerStatus}
      />
    );
    if (workerStatus.status === "PENDING") {
      if (
        workerStatus.payload.stepMessage ===
        "GenerateApprovalExportStatus.REQUEST"
      ) {
        return {
          percentDone: 10,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.REQUEST"
              defaults="Requesting..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateApprovalExportStatus.GENERATE"
      ) {
        return {
          percentDone: 30,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.GENERATE"
              defaults="Generating..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage === "GenerateApprovalExportStatus.SAVE"
      ) {
        return {
          percentDone: 60,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.SAVE"
              defaults="Saving in documents..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateApprovalExportStatus.BROADCAST"
      ) {
        return {
          percentDone: 80,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.BROADCAST"
              defaults="Broadcasting..."
            />
          ),
          title,
        };
      }
    }
    return handleFinishedStatus(workerStatus, title);
  },
  "PROJECT/GENERATE_APPROVAL_REPORT_STATUS": (_ctx, workerStatus) => {
    const title = (
      <Trans
        i18nKey="workerStatus.PROJECT/GENERATE_APPROVAL_REPORT_STATUS.title"
        defaults="Generating approvals report"
        values={workerStatus}
      />
    );
    if (workerStatus.status === "PENDING") {
      if (
        workerStatus.payload.stepMessage ===
        "GenerateApprovalReportStatus.REQUEST"
      ) {
        return {
          percentDone: 10,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.REQUEST"
              defaults="Requesting..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateApprovalReportStatus.GENERATE"
      ) {
        return {
          percentDone: 30,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.GENERATE"
              defaults="Generating..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage === "GenerateApprovalReportStatus.SAVE"
      ) {
        return {
          percentDone: 60,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.SAVE"
              defaults="Saving in documents..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateApprovalReportStatus.BROADCAST"
      ) {
        return {
          percentDone: 80,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.BROADCAST"
              defaults="Broadcasting..."
            />
          ),
          title,
        };
      }
    }
    return handleFinishedStatus(workerStatus, title);
  },
  "PROJECT/GENERATE_FILE_TREE_REPORT_STATUS": (_ctx, workerStatus) => {
    const title = (
      <Trans
        i18nKey="workerStatus.PROJECT/GENERATE_FILE_TREE_REPORT_STATUS.title"
        defaults="Generating file tree report"
        values={workerStatus}
      />
    );
    if (workerStatus.status === "PENDING") {
      if (
        workerStatus.payload.stepMessage ===
        "GenerateFileTreeReportStatus.REQUEST"
      ) {
        return {
          percentDone: 10,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.REQUEST"
              defaults="Requesting..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateFileTreeReportStatus.GENERATE"
      ) {
        return {
          percentDone: 30,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.GENERATE"
              defaults="Generating..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage === "GenerateFileTreeReportStatus.SAVE"
      ) {
        return {
          percentDone: 60,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.SAVE"
              defaults="Saving in documents..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateFileTreeReportStatus.BROADCAST"
      ) {
        return {
          percentDone: 80,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.BROADCAST"
              defaults="Broadcasting..."
            />
          ),
          title,
        };
      }
    }
    return handleFinishedStatus(workerStatus, title);
  },
  "PROJECT/GENERATE_TASK_EXPORT_STATUS": (_ctx, workerStatus) => {
    const title = (
      <Trans
        i18nKey="workerStatus.PROJECT/GENERATE_TASK_EXPORT_STATUS.title"
        defaults="Generating approvals export"
        values={workerStatus}
      />
    );
    if (workerStatus.status === "PENDING") {
      if (
        workerStatus.payload.stepMessage === "GenerateTaskExportStatus.REQUEST"
      ) {
        return {
          percentDone: 10,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.REQUEST"
              defaults="Requesting..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateTaskExportStatus.GENERATE_DATA"
      ) {
        return {
          percentDone: 20,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.GENERATE_DATA"
              defaults="Generating the data..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage === "GenerateTaskExportStatus.SAVE"
      ) {
        return {
          percentDone: 60,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.SAVE"
              defaults="Saving in documents..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateTaskExportStatus.BROADCAST"
      ) {
        return {
          percentDone: 80,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.BROADCAST"
              defaults="Broadcasting..."
            />
          ),
          title,
        };
      }
    }
    return handleFinishedStatus(workerStatus, title);
  },
  "PROJECT/GENERATE_TASK_REPORT_STATUS": (_ctx, workerStatus) => {
    const title = (
      <Trans
        i18nKey="workerStatus.PROJECT/GENERATE_TASK_REPORT_STATUS.title"
        defaults="Generating approvals report"
        values={workerStatus}
      />
    );
    if (workerStatus.status === "PENDING") {
      if (
        workerStatus.payload.stepMessage === "GenerateTaskReportStatus.REQUEST"
      ) {
        return {
          percentDone: 10,
          subtitle: (
            <Trans
              i18nKey="workerStatus.PROJECT/GENERATE_TASK_REPORT_STATUS.PENDING.GenerateTaskReportStatus.REQUEST"
              defaults="Requesting report for {{tasksCount}} tasks"
              values={workerStatus.payload}
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateTaskReportStatus.GENERATE_DATA"
      ) {
        return {
          percentDone: 20,
          subtitle: (
            <Trans
              i18nKey="workerStatus.PROJECT/GENERATE_TASK_REPORT_STATUS.PENDING.GenerateTaskReportStatus.GENERATE_DATA"
              defaults="Generating report data for {{tasksCount}} tasks"
              values={workerStatus.payload}
            />
          ),
          title,
        };
      }

      if (
        workerStatus.payload.stepMessage ===
        "GenerateTaskReportStatus.PREPARED_IMAGES"
      ) {
        return {
          percentDone: 30,
          subtitle: (
            <Trans
              i18nKey="workerStatus.PROJECT/GENERATE_TASK_REPORT_STATUS.PENDING.GenerateTaskReportStatus.PREPARED_IMAGES"
              defaults="Preparing images for {{attachmentsCount}} attachments"
              values={workerStatus.payload}
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateTaskReportStatus.SENT_TO_CARBONE"
      ) {
        return {
          percentDone: 60,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.SENT"
              defaults="Report sent to generator..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateTaskReportStatus.GENERATED"
      ) {
        return {
          percentDone: 60,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.GENERATED"
              defaults="Report Generated"
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage === "GenerateTaskReportStatus.SAVE"
      ) {
        return {
          percentDone: 70,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.SAVE"
              defaults="Saving in documents..."
            />
          ),
          title,
        };
      }
      if (
        workerStatus.payload.stepMessage ===
        "GenerateTaskReportStatus.BROADCAST"
      ) {
        return {
          percentDone: 80,
          subtitle: (
            <Trans
              i18nKey="workerStatus.COMMON.REPORT.BROADCAST"
              defaults="Broadcasting..."
            />
          ),
          title,
        };
      }
    }
    return handleFinishedStatus(workerStatus, title);
  },
  "USER/SELF_ONBOARDING_STATUS": (_ctx, workerStatus) => {
    const title = (
      <Trans
        i18nKey="workerStatus.USER/SELF_ONBOARDING_STATUS.title"
        defaults="User self onboarding"
        values={workerStatus}
      />
    );
    if (workerStatus.status === "PENDING") {
      if (workerStatus.payload.stepMessage === "SelfOnboarding.ORG") {
        return {
          percentDone: 50,
          subtitle: (
            <Trans
              i18nKey="workerStatus.USER/SELF_ONBOARDING_STATUS.PENDING.SelfOnboarding.ORG"
              defaults="Creating org..."
            />
          ),
          title,
        };
      }
      if (workerStatus.payload.stepMessage === "SelfOnboarding.DEMO_PROJECT") {
        return {
          percentDone: 75,
          subtitle: (
            <Trans
              i18nKey="workerStatus.USER/SELF_ONBOARDING_STATUS.PENDING.SelfOnboarding.DEMO_PROJECT"
              defaults="Creating demo project..."
            />
          ),
          title,
        };
      }
    }
    // Handled messages if worker status is finished or unknown
    return handleFinishedStatus(workerStatus, title);
  },
});

export { workerStatusMap };

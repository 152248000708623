import * as React from "react";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";
import { Box } from "~/design-system/Box";
import { Nav, NavList, NavListItem } from "~/design-system/Navigation";
import { Placeholder } from "~/design-system/Placeholder/Placeholder";
import type {
  GroupProjectList,
  OrgSettings,
  UserProject,
} from "../../NavigationDrawer";
import type { NavigationDrawer_UserQuery } from "../../NavigationDrawer.graphql";
import { NoProject } from "./components/NoProject";
import {
  PROJECT_ITEM_HEIGHT,
  ProjectItem,
} from "./components/ProjectItem/ProjectItem";
import { ProjectListHeader } from "./components/ProjectListHeader";
import { ProjectListByGroupMenu } from "./ProjectListByGroup";

type ProjectListMenuProps = {
  projects: UserProject[];
  activeProjectId?: string;
  closeDrawer: () => void;
  groupProjectList?: GroupProjectList;
  orgSettings?: OrgSettings;
};

type UserNavigationDrawer = NonNullable<NavigationDrawer_UserQuery["user"]>;
type ProjectNavigationDrawer = NonNullable<
  UserNavigationDrawer["projects"]
>[0]["project"];
type ProjectSummary = NonNullable<
  ProjectNavigationDrawer["org_project_summary"]
>[0];

const useProjectListMenu = (props: ProjectListMenuProps) => {
  const parentRef = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState<string>();
  const [searchMode, setSearchMode] = React.useState<boolean>(false);
  const filteredProjects = props.projects
    .filter((p) => {
      if (!p.org_project_summary?.length) {
        // if the user is not a member of an org, we can't filter by is_archived
        // so we need to show all projects
        return true;
      }
      return !p.org_project_summary[0].is_archived;
    })
    .filter((p) =>
      searchQuery
        ? p.name.toLowerCase().includes(searchQuery.toLowerCase())
        : true
    );
  const rowVirtualizer = useVirtual({
    estimateSize: React.useCallback(() => PROJECT_ITEM_HEIGHT, []),
    parentRef,
    size: filteredProjects.length,
  });
  const { t } = useTranslation();

  const groupProjectList = props.groupProjectList ?? [];

  const projectsListByGroup =
    !groupProjectList || groupProjectList.length === 0
      ? []
      : groupProjectList.map((category) => {
          const rowFilteredByGroup = filteredProjects.filter(
            (projectItem: ProjectNavigationDrawer) =>
              projectItem.org_project_summary?.some((ops: ProjectSummary) => {
                return ops.project_groups.some(
                  (pc: {
                    project_group: { name: string | null | undefined };
                  }) => pc.project_group.name === category.name
                );
              })
          );
          return { ...category, projects: rowFilteredByGroup };
        });

  const projectListWithOutGroup = [
    {
      avatar: undefined,
      color: "brown",
      name: t(
        "NavigarionDrawer.ProjectListMenu.WithOutGroup",
        "Projects without groups"
      ),
      projects: filteredProjects.filter(
        (projectItem: ProjectNavigationDrawer) =>
          !projectItem.org_project_summary ||
          projectItem.org_project_summary?.length === 0 ||
          projectItem.org_project_summary.some(
            (ops: ProjectSummary) =>
              !ops.project_groups ||
              ops.project_groups.length === 0 ||
              ops.project_groups.some(
                (pc: { project_group: { name: string | null | undefined } }) =>
                  pc.project_group.name === undefined ||
                  pc.project_group.name === null
              )
          )
      ),
    },
  ];

  const showMenuByGroup = props.orgSettings?.show_menu_by_group ?? false;

  return {
    actions: {
      setSearchMode,
      setSearchQuery,
    },
    refs: {
      parentRef,
    },
    state: {
      groupProjectList,
      projects: filteredProjects,
      projectsListByGroup: [...projectsListByGroup, ...projectListWithOutGroup],
      rowVirtualizer,
      searchMode,
      searchQuery,
      showMenuByGroup,
    },
  };
};

function ProjectListMenu(props: ProjectListMenuProps) {
  const { actions, refs, state } = useProjectListMenu(props);
  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <ProjectListHeader
        closeDrawer={props.closeDrawer}
        setSearchQuery={actions.setSearchQuery}
        searchQuery={state.searchQuery}
        setSearchMode={actions.setSearchMode}
      />
      {state.projects && state.projects.length > 0 ? (
        <Nav
          ref={refs.parentRef}
          css={{
            flexGrow: 1,
            overflowY: "auto",
          }}
          label="Projects List"
        >
          {(!state.showMenuByGroup || state.searchMode) && (
            <NavList
              css={{
                color: "white",
                height: `${state.rowVirtualizer.totalSize}px`,
                position: "relative",
                width: "100%",
              }}
            >
              {state.rowVirtualizer.virtualItems.map((virtualRow) => {
                const row = state.projects[virtualRow.index];
                return (
                  <NavListItem
                    key={virtualRow.index}
                    ref={virtualRow.measureRef}
                    css={{
                      height: `${virtualRow.size}px`,
                      left: 0,
                      position: "absolute",
                      top: 0,
                      transform: `translateY(${virtualRow.start}px)`,
                      width: "100%",
                    }}
                  >
                    <ProjectItem
                      data={row}
                      active={row.id === props.activeProjectId}
                    />
                  </NavListItem>
                );
              })}
            </NavList>
          )}
          {state.showMenuByGroup && !state.searchMode && (
            <ProjectListByGroupMenu
              projectListByGroup={state.projectsListByGroup}
              activeProjectId={props.activeProjectId}
            />
          )}
          {/*Add a placeholder help the user to see easily the last item*/}
          <Placeholder background="body" height="xxlarge" width="full" />
        </Nav>
      ) : (
        <NoProject closeDrawer={props.closeDrawer} />
      )}
    </Box>
  );
}

export type { ProjectListMenuProps, ProjectNavigationDrawer };
export { ProjectListMenu };

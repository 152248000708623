import { IOrgInfoNotificationTypePrefix } from "@clovis/server/src/app/domain/notification/create-notification/org/info/types";
import { IOrgNotificationTypePrefix } from "@clovis/server/src/app/domain/notification/create-notification/org/types";
import { IChatNotificationTypePrefix } from "@clovis/server/src/app/domain/notification/create-notification/project/chat/types";
import {
  IDocumentNotificationTypePrefix,
  IFileNotificationTypePrefix,
  IFolderNotificationTypePrefix,
} from "@clovis/server/src/app/domain/notification/create-notification/project/document/types";
import { IProjectMiscNotificationTypePrefix } from "@clovis/server/src/app/domain/notification/create-notification/project/misc/types";
import { ITaskNotificationTypePrefix } from "@clovis/server/src/app/domain/notification/create-notification/project/task/types";
import { IProjectNotificationTypePrefix } from "@clovis/server/src/app/domain/notification/create-notification/project/types";
import type {
  ChatNotification,
  DomainPrismaNotification,
  OrgNotification,
  ProjectChatNotification,
  ProjectDocumentNotification,
  ProjectFileNotification,
  ProjectFolderNotification,
  ProjectNotification,
  ProjectTaskChatNotification,
  StrongNotification,
  TaskNotification,
  UserBadgeNotification,
  WeakNotification,
} from "./types";

function isStrongNotification(
  notif: UserBadgeNotification
): notif is StrongNotification {
  return notif.is_strong === true;
}

function isWeakNotification(
  notif: UserBadgeNotification
): notif is WeakNotification {
  return notif.is_strong === false;
}

function isOrgNotification(
  notif: UserBadgeNotification
): notif is OrgNotification {
  return notif.type.startsWith(IOrgNotificationTypePrefix);
}

function isTaskNotification(
  notif: UserBadgeNotification
): notif is TaskNotification {
  return notif.type.startsWith(ITaskNotificationTypePrefix);
}

function isChatNotification(
  notif: UserBadgeNotification
): notif is ChatNotification {
  return notif.type.startsWith(IChatNotificationTypePrefix);
}

function isProjectChatNotification(
  notif: UserBadgeNotification
): notif is ProjectChatNotification {
  return notif.type.startsWith(IChatNotificationTypePrefix);
}

function isProjectDocumentNotification(
  notif: UserBadgeNotification
): notif is ProjectDocumentNotification {
  return notif.type.startsWith(IDocumentNotificationTypePrefix);
}

function isProjectFileNotification(
  notif: UserBadgeNotification
): notif is ProjectFileNotification {
  return notif.type.startsWith(IFileNotificationTypePrefix);
}
function isProjectFolderNotification(
  notif: UserBadgeNotification
): notif is ProjectFolderNotification {
  return notif.type.startsWith(IFolderNotificationTypePrefix);
}

function isProjectTaskChatNotification(
  notif: UserBadgeNotification
): notif is ProjectTaskChatNotification {
  return notif.type.startsWith(`${IChatNotificationTypePrefix}/TASK`);
}

function isProjectNotification(
  notif: UserBadgeNotification
): notif is ProjectNotification {
  return notif.type.startsWith(IProjectNotificationTypePrefix);
}

function isMemberInvitedToOrgNotification(
  notif: UserBadgeNotification
): notif is DomainPrismaNotification<"ORG/USER_INVITED_TO_ORG"> {
  return notif.type === "ORG/USER_INVITED_TO_ORG";
}

function isOrgRoleUpdatedNotification(
  notif: UserBadgeNotification
): notif is OrgNotification {
  return notif.type.startsWith(`${IOrgNotificationTypePrefix}/ROLE_UPDATED`);
}

function isProjectRoleUpdatedNotification(
  notif: UserBadgeNotification
): notif is ProjectNotification {
  return notif.type.startsWith(
    `${IProjectMiscNotificationTypePrefix}/ROLE_UPDATED`
  );
}

function isProjectArchiveUpdatedNotification(
  notif: UserBadgeNotification
): notif is ProjectNotification {
  return notif.type.startsWith(
    `${IOrgInfoNotificationTypePrefix}/PROJECT_ARCHIVE_UPDATED`
  );
}

function isProjectDeletedNotification(
  notif: UserBadgeNotification
): notif is ProjectNotification {
  return notif.type.startsWith(
    `${IProjectMiscNotificationTypePrefix}/PROJECT_DELETED`
  );
}

export {
  isChatNotification,
  isMemberInvitedToOrgNotification,
  isOrgNotification,
  isOrgRoleUpdatedNotification,
  isProjectArchiveUpdatedNotification,
  isProjectChatNotification,
  isProjectDeletedNotification,
  isProjectDocumentNotification,
  isProjectFileNotification,
  isProjectFolderNotification,
  isProjectNotification,
  isProjectRoleUpdatedNotification,
  isProjectTaskChatNotification,
  isStrongNotification,
  isTaskNotification,
  isWeakNotification,
};

import type {
  IOrgNotification,
  IOrgNotificationPayload,
} from "@clovis/server/src/app/domain/notification/create-notification/org/types";
import { IOrgNotificationTypePrefix } from "@clovis/server/src/app/domain/notification/create-notification/org/types";

interface IOrgInfo {
  id: string;
}

interface IOrgInfoNotificationPayload extends IOrgNotificationPayload {
  org: IOrgInfo;
}

const IOrgInfoNotificationTypePrefix =
  `${IOrgNotificationTypePrefix}/INFO` as const;

interface IOrgInfoNotification extends IOrgNotification {
  type: `${typeof IOrgInfoNotificationTypePrefix}/${string}`;
  readonly payload: IOrgInfoNotificationPayload;
}

export type { IOrgInfoNotification, IOrgInfoNotificationPayload };
export { IOrgInfoNotificationTypePrefix };
